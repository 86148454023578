import React from 'react';
import type { ICase } from 'app/mobxStore/types';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import Chip from 'app/components/chip/Chip';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';

const getCaseStatus = (
  t: TFunction<'translation', undefined>,
  kase: ICase
): React.JSX.Element | null => {
  const onClick = (): void => {
    void kase.setStandardProcedureToServer(false);
    kase.setStandardProcedureToStore(false);
    kase.setIsReady(false);
    toast.success(t('notStandardProcedure'));
  };

  if (!kase.data.isReady) {
    return null;
  }

  if (kase.data.standardProcedure) {
    return (
      <Chip
        classNames={'case-status standard-proc'}
        text={'standardProcedureChip'}
        color={'success'}
        onClick={onClick}
      />
    );
  }

  return <Chip classNames={'case-status'} text={'huddled'} color={'success'} />;
};

const CaseStatusChip = (props: { case: ICase }): React.JSX.Element | null => {
  const { t } = useTranslation();
  const { case: kase } = props;

  return getCaseStatus(t, kase);
};

export default observer(CaseStatusChip);
